<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-04">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">설문조사참여</h1>
        <div class="feature-body">
          <p>BMS 제품 및 관련 질환 관련 다양한 설문조사에 참여하고, 그 통계를 조회하실 수 있습니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">BMS 소식</a></li>
            <li class="breadcrumb">설문조사참여</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->
      <div class="section">
        <!--본문 영역 s-->
        <div class="b_view" ref="document" id="print-area">
          <div class="b_view_tit">
            <h1>{{ title }}</h1>
            <div class="list_date">
              <span>Date</span> {{ date }}
              <span>Views</span> {{ viewCount }}
            </div>
            <shared-area
                :file-name="fileName"/>
          </div>

          <div style="padding:56.25% 0 0 0;position:relative;" class="ignore-pdf" v-if="videoUrl">
            <iframe
                :src="videoUrl"
                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
          </div>
          <div class="b_view_body">
            <div class="b_view_tit" v-html="question"/>
            <template v-if="!participationStatus">

              <div class="surv_que" v-for="(questionUnit, index) in surveyQuestions" :key="index">
                <div class="que_tit_area">
                  <div class="que_tit">Question</div>
                  <div class="que_text">{{ questionUnit.question }}</div>
                </div>

                <ul>
                  <li v-for="(option,index2) in questionUnit['optionList']" :key="index2">
                    <input type="radio" :name="`ans-${index}`" :value="index2" :id="`${index}${index2}`"
                           v-model="selectedOption[index]">
                    <label :for="`${index}${index2}`" v-text="option"></label>
                  </li>
                </ul>
              </div>

              <div class="btn_area" @click="submit">
                <button class="btn_main">답변 제출하기</button>
              </div>

            </template>
            <template v-else>
              <div class="surv_que" v-for="(chart, index) in numericalStatement"
                   :key="index">
                <div class="que_tit_area">
                  <p class="que_tit que_tit2">다른 선생님들은 아래와 같이 답변하였습니다.</p>
                </div>
                <div class="que_box2">
                  <canvas :id="`planet-chart-${index.replace(/ /g,'_')}`"/>
                </div>
              </div>
              <div class="b_view_body" v-html="answer"/>
            </template>
          </div>

          <div class="related_contents" v-if="Array.isArray(relatedContents) && relatedContents.length !== 0">
            <h1>Related Contents</h1>
            <ul>
              <li v-for="(relatedContent, index) in relatedContents" :key="index">
                <a :href="relatedContent.redirectUrl">
                  <img :src="relatedContent.thumbnailUrl" :alt="relatedContent.title">
                  <p>{{ relatedContent.title }}</p>
                </a>
              </li>
            </ul>
          </div>
          <!--more버튼-->
          <!--          <div class="btn_area btn_more">-->
          <!--            <button>Load More</button>-->
          <!--          </div>-->
        </div>
        <!--본문 영역 e-->

        <!--list view 버튼-->
        <div class="btn_area_right btn_listview">
          <a href="/news/survey-event">
            <button>List View</button>
          </a>
        </div>

      </div>
    </div>
  </main>
</template>

<script>
import SharedArea from "@/components/common/SharedArea";
import Chart from 'chart.js'
import surveyAPI from "@/api/surveyAPI";

export default {
  name: "SurveyEventView",
  components: {SharedArea},
  data() {
    return {
      title: '',
      date: '',
      viewCount: '',
      videoUrl: '',
      relatedContents: [],
      fileName: 'BMSON-설문조사참여',
      question: '',
      answer: '',
      numericalStatement: {},
      participationStatus: false,
      surveyQuestions: [],
      selectedOption: [],
    }
  },
  methods: {
    submit() {
      if (this.selectedOption.includes(undefined)) {
        alert('설문조사가 완료되지 않았습니다. 모든 항목을 체크하여 주시기 바랍니다.')
        return false
      }
      const question = this.surveyQuestions.map((value, index) => {
        return {
          answerId: value.id,
          optionId: this.selectedOption[index]
        }
      })
      surveyAPI.submitSurvey(this.$route.params.surveyEventNo, {
        surveyAnswerList: question
      }).then(() => {
        this.fetchSurvey()
      })
    },
    createChart(charId, chartData) {
      const ctx = document.getElementById(charId)
      new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      });
    },
    fetchSurvey() {
      this.$store.dispatch('fetchSurvey', {
        id: this.$route.params.surveyEventNo
      }).then(survey => {
        this.numericalStatement = survey.numericalStatement
        this.title = survey.title
        this.date = survey.date
        this.viewCount = survey.viewCount
        this.videoUrl = survey.videoUrl
        this.relatedContents = survey.relatedContents
        this.question = survey.question
        this.answer = survey.answer
        this.participationStatus = survey.participationStatus
        this.surveyQuestions = survey.surveyQuestions
        this.selectedOption.length = survey.surveyQuestions.length
        this.$nextTick(() => {
          for (const [key, value] of Object.entries(this.numericalStatement)) {
            let labels = []
            let data = []
            let state = value
            let coloR = [];
            let dynamicColors = function () {
              let r = Math.floor(Math.random() * 255);
              let g = Math.floor(Math.random() * 255);
              let b = Math.floor(Math.random() * 255);
              return "rgb(" + r + "," + g + "," + b + ")";
            };

            for (const [key, value] of Object.entries(state)) {
              labels.push(key)
              data.push(value)
              coloR.push(dynamicColors())
            }


            this.createChart(`planet-chart-${key.replace(/ /g, '_')}`, {
              type: 'pie',
              data: {
                labels: labels,
                datasets: [{
                  label: key.replace(/ /g, '_'),
                  data: data,
                  backgroundColor: coloR,
                  hoverOffset: 4
                }]
              },
              options: {
                tooltips: {
                  callbacks: {
                    label: (tooltipItems, data) => {
                      return `${data.labels[tooltipItems.datasetIndex]} : ${data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]} 개`;
                    }
                  },
                },
              }
            })
          }
        })

      })
    }
  },
  mounted() {
    this.fetchSurvey()
  },
}
</script>

<style scoped>

</style>
